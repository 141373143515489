:root {
    // Secondary Button
    --p-button-secondary-background: var(--p-secondary-500);
    --p-button-secondary-border-color: var(--p-secondary-500);
    --p-button-secondary-color: var(--p-secondary-50);
    --p-button-secondary-hover-background: var(--p-secondary-600);
    --p-button-secondary-hover-border-color: var(--p-secondary-600);
    --p-button-secondary-hover-color: var(--p-secondary-50);
    --p-button-secondary-active-background: var(--p-secondary-400);
    --p-button-secondary-active-border-color: var(--p-secondary-400);
    --p-button-secondary-active-color: var(--p-secondary-800);
    --p-button-secondary-focus-ring-color: var(--p-secondary-20);
    --p-button-secondary-focus-ring-shadow: none;

    // Secondary Text Button
    // --p-button-text-secondary-color: var(--p-secondary-500);

    // Info Button
    --p-button-info-background: var(--p-zinc-200);
    --p-button-info-border-color: var(--p-zinc-200);
    --p-button-info-color: var(--p-zinc-900);
    --p-button-info-hover-background: var(--p-zinc-300);
    --p-button-info-hover-border-color: var(--p-zinc-300);
    --p-button-info-hover-color: var(--p-zinc-900);
    --p-button-info-active-background: var(--p-zinc-400);
    --p-button-info-active-border-color: var(--p-zinc-400);
    --p-button-info-active-color: var(--p-zinc-900);
    --p-button-info-focus-ring-color: var(--p-zinc-20);
    --p-button-info-focus-ring-shadow: none;

    // Contrast Button (BLUE)
    --p-button-contrast-background: var(--p-sky-500);
    --p-button-contrast-border-color: var(--p-sky-500);
    --p-button-contrast-color: var(--p-sky-50);
    --p-button-contrast-hover-background: var(--p-sky-600);
    --p-button-contrast-hover-border-color: var(--p-sky-600);
    --p-button-contrast-hover-color: var(--p-sky-50);
    --p-button-contrast-active-background: var(--p-sky-400);
    --p-button-contrast-active-border-color: var(--p-sky-400);
    --p-button-contrast-active-color: var(--p-sky-800);
    --p-button-contrast-focus-ring-color: var(--p-sky-20);
    --p-button-contrast-focus-ring-shadow: none;

    // Progress bar
    --p-progressbar-value-background: var(--p-secondary-500);

    // Toggle Button
    --p-toggleswitch-checked-background: var(--p-secondary-500);

    // Menu headings
    --p-menu-submenu-label-color: var(--p-primary-500);
    --p-menu-item-padding: 0.75rem 0.75rem;

    // Tables
    --p-datatable-header-border-width: 1px 0 1px 0;
    --p-datatable-header-background: var(--p-gray-50);
    --p-datatable-header-cell-background: var(--p-gray-50);
    --p-datatable-row-striped-background: var(--p-gray-50);
    --p-datatable-header-cell-hover-background: var(--p-primary-50);

    // Checkbox
    --p-checkbox-checked-background: var(--p-secondary-500);
    --p-checkbox-checked-border-color: var(--p-secondary-500);
    --p-checkbox-checked-hover-background: var(--p-secondary-600);
    --p-checkbox-checked-hover-border-color: var(--p-secondary-600);

    // Tag
    --p-tag-secondary-background: var(--p-secondary-100);
    --p-tag-secondary-color: var(--p-secondary-500);

    --p-tag-info-background: var(--p-zinc-100);
    --p-tag-info-color: var(--p-zinc-500);

    // Gallery
    --p-galleria-border-width: 0;
    --p-galleria-border-color: transparent;
    --p-galleria-border-radius: 0;

    // File Upload
    --p-fileupload-content-gap: 0;
    --p-fileupload-header-padding: 0;
    --p-fileupload-header-border-width: 0;
    --p-fileupload-header-border-color: transparent;
    --p-fileupload-border-color: transparent;
}

.surface-ground {
    background-color: var(--p-surface-100);
}

.surface-section {
    background-color: var(--p-surface-0);
}

.surface-panel {
    background-color: var(--p-surface-50);
}

.surface-border {
    border-color: var(--p-surface-200);
}

.surface-secondary {
    background-color: var(--p-secondary-50);
}
