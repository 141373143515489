@use "./theme/variables.scss";
@import "./theme/typography.scss";
@import "primeicons/primeicons.css";

// Website header font
@import url("https://fonts.googleapis.com/css2?family=Inter:opsz,wght@14..32,100..900&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100..900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@400;700&display=swap");

/* You can add global styles to this file, and also import other style files */
@layer tailwind-base, primeng, tailwind-utilities;
@layer tailwind-base {
    @tailwind base;
}
@layer primeng;
@layer tailwind-utilities {
    @tailwind components;
    @tailwind utilities;
}

html {
    font-size: 14px;
    body {
        h1,
        h2 {
            font-family: "Teko", sans-serif;
            font-weight: 700;
            margin-top: 5px;
            color: var(--p-primary-500);
        }
    }
}

@media (prefers-color-scheme: dark) {
    .dark-image {
        display: block;
    }

    .light-image {
        display: none;
    }
}

@media (prefers-color-scheme: light) {
    .dark-image {
        display: none;
    }

    .light-image {
        display: block;
    }
}

/* Override prime setting, ensure icons always spin */
@media (prefers-reduced-motion: reduce) {
    .pi-spin {
        -webkit-animation: fa-spin 2s infinite linear;
        animation: fa-spin 2s infinite linear;
    }
}

.text-danger {
    color: var(--p-red-500);
}

.text-success {
    color: var(--p-green-500);
}

.text-secondary {
    color: var(--p-secondary-500);
}

p-badge.badge-secondary {
    background-color: var(--p-secondary-500);
}

.p-inputgroup {
    border-radius: var(--p-inputtext-border-radius);

    .p-password .p-inputtext {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

// if .p-inputtext is last child of p-inputgroup
.p-inputgroup:has(> :last-child:nth-child(3)) {
    .p-password .p-inputtext {
        border-radius: 0;
    }
}

.register-screen .tz-dropdown .p-select {
    width: 100%;

    .p-inputgroup-addon {
        border-radius: 0;
        box-shadow: none;
    }

    .p-select-label {
        padding: 0;
    }
}

.p-button-dark {
    background-color: var(--p-gray-900);
    border-color: var(--p-gray-900);
    color: var(--p-gray-50);
}

.p-button-dark:enabled:hover {
    background-color: var(--p-gray-700);
    border-color: var(--p-gray-700);
}

/*
 PRIME FACES
*/
.p-splitbutton.p-component,
.p-splitbutton-button {
    width: 100%;
}

p-galleria .p-overlay-mask {
    background-color: rgba(0, 0, 0, 0.9);
}

.p-datepicker-clear-icon {
    right: 0.8rem;
    top: 0.8rem;
    position: absolute;
    color: var(--p-select-dropdown-color);
    cursor: pointer;
}

.p-breadcrumb {
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    padding: 1rem;
}

.breadcrumb-active-element {
    font-weight: 500;
    pointer-events: none;
}

.options-fixed-sidemenu {
    width: 100% !important;
    border: 0px solid transparent;
}

.p-sidebar-mask {
    z-index: 1;
}

.p-card-no-padding .p-card-body,
.p-card-no-padding .p-card-content {
    padding: 0 !important;
}

.button-nowrap {
    white-space: nowrap;
    text-overflow: ellipsis;
}

.buy-premium-wrapper {
    border: 1px solid var(--p-secondary-500);
    color: var(--p-secondary-500);
    border-radius: 5px;
    background: var(--p-secondary-50);
}

.subscription-status-wrapper {
    border: 1px solid var(--p-surface-400);
    background: var(--p-surface-100);
    border-radius: 5px;
    font-weight: bold;
}

.p-confirmdialog-message {
    white-space: pre-wrap;
}

// Reduce default padding slightly
.p-toolbar {
    background: var(--surface-section);
    padding: 0.5rem 1rem;
    border-radius: 0;
    border: none;
    height: 60px;
}

.mobile-toolbar {
    z-index: 1;
}

.toolbar-title h1 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 320px;
    max-width: 80%;
}

@media (min-width: 415px) {
    .home-screen-datatable-wrapper,
    .list-datatable-fullscreen-wrapper {
        z-index: 2;
        height: calc(100vh - 60px);
    }

    p-toolbar::after {
        z-index: 1;
        height: 4px;
        position: absolute;
        top: 60px;
        right: 0px;
        left: 0px;
        background: linear-gradient(
            rgba(9, 30, 66, 0.13) 0px,
            rgba(9, 30, 66, 0.13) 1px,
            rgba(9, 30, 66, 0.08) 1px,
            rgba(9, 30, 66, 0) 4px
        );
        content: "";
        background-color: var(--surface-ground);
    }
}

@media (max-width: 414px) {
    p-toolbar .p-home-toolbar {
        height: 92px !important;
    }

    .home-screen-datatable-wrapper {
        height: calc(100vh - 92px);
    }
}

// Data table options
.p-datatable-row-group-header {
    background-color: var(--p-gray-50);
    font-weight: bold;
}

.flex-table-wrapper {
    height: 2000px;
    max-height: 80vh;
}

.datatableRowHover:hover {
    cursor: pointer;
    background: var(--p-datatable-header-cell-hover-background);

    .td-hover {
        text-decoration: underline;
    }
}

.input-compact {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

// Home Screen Datatable
.p-dialog-maximize-button {
    background-color: transparent;
    border-color: transparent;
    color: var(--p-button-text-secondary-color);
}

.home-datatable-wrapper {
    min-height: 50vh;
    border-radius: 10px;
    border: 1px solid var(--p-surface-100);
    overflow: hidden;
}

.home-project-type-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
}

// reduce padding around datatable inputs
.p-datatable .p-datatable-header,
.p-datatable .p-datatable-thead > tr > th {
    padding: 0.5rem 1rem;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover,
.p-column-filter-clear-button,
.p-column-filter-clear-button:hover {
    color: var(--p-secondary-500);
}

.p-column-filter-row .p-hidden-space {
    display: none;
}

.pcColumnfilterClearButton {
    color: var(--p-secondary-500);
}

// Home page
.menu-dropdown-overlay {
    width: 290px;
    max-width: 500px;
    --p-popover-arrow-offset: 268px;
}

// PROJECT SCREENS
.list-datatable-fullscreen-wrapper {
    .p-datatable .p-datatable-header {
        padding: 0;
        border-width: 0 0 1px 0;
    }
}

/* Limit the height of datatables on the
  Project > Project Lists
  List > Defet Lists
*/
@media (min-width: 1024px) {
    .list-datatable-wrapper {
        height: calc(100vh - 280px);
    }

    .fullscreen-with-breadcrumbs {
        height: calc(100vh - 140px);
    }

    .list-datatable-fullscreen-wrapper {
        height: 100vh;
    }
}

@media (max-width: 1023px) {
    .list-datatable-wrapper {
        height: calc(100vh - 350px);
    }

    .fullscreen-with-breadcrumbs {
        height: calc(100vh - 170px);
    }

    .list-datatable-fullscreen-wrapper {
        height: calc(100vh - 60px);
    }

    p-breadcrumb li.p-element {
        white-space: nowrap;
        max-width: 25%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.popover-menu-reports {
    min-width: 160px;
}

// LIST SCREEN - Hide the Defect Side Panel
.p-splitter {
    background-color: var(--surface-ground) !important;
    // Required generic width value for whatever reason
    .myPanelClass .listPanel {
        width: 400px;
    }
}

.hideDefectClass div:nth-child(2).p-splitter-gutter,
.hideDefectClass div:nth-child(3).myPanelClass {
    display: none;
}

.list-thumbnail-cell {
    padding: 0.5rem !important;

    .list-thumbnail-wrapper {
        height: 60px;
        width: 60px;
        border: 1px solid var(--p-surface-200);
        border-radius: 5px;
    }

    .list-thumbnail {
        border-radius: 5px;
        height: 60px;
        max-height: 60px;
        width: 60px;
        max-width: 60px;
        object-fit: cover;
    }
}

// limit width of the status dropdown
.multi-select-status {
    max-width: 160px;
    // display: inline-block;
}

// DEFECT SCREEN
.defect-dialog {
    .p-dialog-header {
        padding: 0;
    }

    .p-dialog-content {
        border-radius: var(--p-dialog-border-radius);
    }
}

.defect-status-picker {
    div.p-button {
        width: 33%;
        white-space: nowrap;
        text-align: center;
        text-overflow: ellipsis;
        padding-left: 10px;
        padding-right: 10px;
        display: inline-block;
    }

    div:nth-child(1).p-highlight {
        border-color: var(--p-secondary-500);
        background-color: var(--p-secondary-500);
    }

    div:nth-child(2).p-highlight {
        border-color: var(--p-sky-500);
        background-color: var(--p-sky-500);
    }

    div:nth-child(3).p-highlight {
        border-color: var(--p-green-500);
        background-color: var(--p-green-500);
    }
}

/* Defect Page */
.defect-image-row-wrapper {
    padding-right: 1em;

    .defect-p-image {
        border-radius: 5px;
        margin: 0 0 0 1em;
    }

    .add-image-thumbnail .add-image-thumbnail-content {
        border-radius: 5px;
        border: 2px dashed var(--p-surface-400);
        height: 120px;
        width: 120px;

        img {
            margin: 0 auto;
        }
    }

    .add-image-thumbnail-content:hover {
        background-color: var(--p-secondary-50);
        border: 2px dashed var(--p-secondary-500);
        transition: all 0.2s;
        cursor: pointer;
    }

    .add-image-thumbnail .p-fileupload .p-fileupload-content {
        padding: 0;
        border: none;
    }

    .add-image-thumbnail .p-fileupload-buttonbar,
    .add-image-thumbnail .p-progressbar {
        display: none;
    }
}

p-multiselect {
    width: 100%;

    .p-multiselect-token {
        margin-right: 0.5em;
    }
}

.defects-dialog-popup {
    .p-dialog .p-dialog-content:last-of-type {
        border-radius: 6px;
    }
}

// OPTIONS
.custom-report-text,
.custom-report-text > p,
.custom-report-text > h1,
.custom-report-text > h2,
.custom-report-text > h3 {
    margin: 0;
}

.p-menu .p-menuitem-badge.badge-secondary {
    background: var(--p-secondary-500);
}

/*
    General 
*/
.alert-btn-danger {
    color: var(--p-red-500) !important;
}

.p-dialog-title {
    color: var(--p-primary-500);
}

/*
    Other
*/
.thumb-icon {
    height: 30px !important;
    width: 30px !important;
}

.thumb-mini-icon {
    height: 14px !important;
    width: 14px !important;
}

.dark-image {
    display: none;
}

.light-image {
    display: block;
}

.text-no-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

//
.counter-input .p-inputnumber-input {
    background-color: var(--p-surface-50);
    border-color: transparent;
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
}

// change :dark to :light to preview
// @media (prefers-color-scheme: dark) {
//     .light-image {
//         display: none !important;
//     }

//     .dark-image {
//         display: block !important;
//     }
// }

/** Image Picker __markerjs2_ styling */

/** Modify padding for ios iPhone X */
@supports (padding: max(0px)) {
    /** env() only supported by ios 11+ */
    .__markerjs2_ {
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
        padding-top: env(safe-area-inset-top);
        padding-bottom: env(safe-area-inset-bottom);
    }
}

/** Background colour behind image and between
    the Toolbar(top) and Toolbox(bottom) bars */
.__markerjs2_ > div > div {
    background-color: black !important;
}

/** Colour options for Toolbar(top) and Toolbox(bottom) bars */
.__markerjs2_ > div .marker-cust-toolbar,
.__markerjs2_ > div .marker-cust-toolbox-panel {
    color: white !important;
    background-color: #222222 !important;
}

/** Hide Logo */
.__markerjs2_ a[target="_blank"] {
    display: none !important;
}

/** Hide Logo */
.__cropro_ a[target="_blank"] {
    display: none !important;
}

//
.disabled-banner {
    padding: 0.5rem;
    width: 100%;
    text-align: center;
    background-color: var(--p-secondary-500);
    color: #ffffff;
    border-radius: 4px;
}

// This is the logo on the Home > Options > Company Info screen
.actual-logo {
    display: block;
    width: auto;
    height: 100%;
}

// This is the Project Photo on the Project > Settings screen
.actual-project-image {
    display: block;
    width: 100%;
    height: auto;
}

// Handles innerHTML notes styling for report preview
.report-notes p {
    margin: 0px !important;
}

/*
    simple method to limit and align content
    > project settings
    > list settings
*/
.limit-width {
    max-width: 700px;
    width: 100%;
    margin: auto;
}

/* 
    Autocompleter classes
    > project list settings for client selection
*/
li.invalid-email {
    background: var(--red-100) !important;
    color: var(--red-800) !important;
}

li.active-user {
    background: var(--green-100) !important;
    color: var(--green-800) !important;
}

// tenant page dropdown
.tz-dropdown {
    .p-select {
        min-width: 300px;
    }
}

.register-screen .tz-dropdown .p-select {
    width: 100%;

    .p-inputgroup-addon {
        border-radius: 0;
        box-shadow: none;
    }

    .p-select-label {
        padding: 0;
    }
}

.generate-report-modal .p-select {
    width: 100%;
}

// TIPTAP
.tiptap p.is-editor-empty:first-child::before {
    color: var(--p-multiselect-placeholder-color);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}
